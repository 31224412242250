.container-home-intro { position: relative; z-index: 10; }
.page-home-intro { padding: 200px 0; min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100); box-sizing: border-box; display: flex; flex-flow: column; justify-content: center; }
.home-intro-inside { width: 100%; display: grid; grid-template-columns: 1fr 104px 408px; grid-template-rows: 1fr; grid-column-gap: 0px; grid-row-gap: 0px; }
.home-intro-image {
    & { grid-area: 1 / 2 / 2 / 4; z-index: 1; }
    img { width: 100%; }
}
.home-intro-content { grid-area: 1 / 1 / 2 / 3; z-index: 2; }
.home-intro-title { @extend .elements-text-title; padding: 120px 0 50px 0; }
.home-intro-scroll { font-weight: 700; font-size: 14px; line-height: 17px; letter-spacing: 4px; text-transform: uppercase; }
@media only screen and (max-width: $size-wide) {
    .page-home-intro { padding: 200px 0 180px; }
    .home-intro-title { padding-top: 80px; }
}
@media only screen and (max-width: $size-desktop) {
    .page-home-intro { padding: 180px 0 160px; }
    .home-intro-title { padding-top: 50px; }
}
@media only screen and (max-width: $size-laptop) {
    .page-home-intro { padding: 160px 0 140px; }
    .home-intro-inside { grid-template-columns: 520px 1fr 360px; }
    .home-intro-image { grid-area: 1 / 3 / 2 / 4; }
    .home-intro-content { grid-area: 1 / 1 / 2 / 2; }
}
@media only screen and (max-width: $size-tablet) {
    .page-home-intro { padding: 170px 0 120px; }
    .home-intro-inside { width: 520px; display: block; margin: 0 auto; }
    .home-intro-image {
        & { text-align: center; width: 328px; margin: 0 auto; }
        img { width: 328px; }
    }
    .home-intro-title { padding: 0 0 54px; }
    .home-intro-scroll { position: absolute; left: 50%; bottom: 30px; width: 200px; margin-left: -100px; text-align: center; font-size: 12px; line-height: 15px; letter-spacing: 3px; }
}
@media only screen and (max-width: $size-phone) {
    .container-home-intro { z-index: 1; }
    .page-home-intro { padding: 95px 0 60px; }
    .home-intro-inside { width: auto; margin: 0; }
    .home-intro-title { padding: 0 0 50px; }
    .home-intro-image {
        & { position: relative; left: -25px; right: -25px; width: calc(100% + 50px); }
        img { width: 100%; }
    }
    .home-intro-scroll { display: none; }
}