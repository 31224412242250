.single-zoom {
    & { height: #{'max(850px, 100vh)'}; position: relative; }
    img { position: absolute; top: 0; left: 0; width: 100%; height: 850px; transform: scale(0.83); transition: all 0.6s ease; object-fit: cover; }
    &--scrolled img { width: 100%; height: #{'max(850px, 100vh)'}; transform: scale(1); }
}
.single-zoom-button {
    & { position: absolute; top: 50%; left: 50%; z-index: 10; transform: translate(-50%, -50%); display: flex; flex-flow: column nowrap; align-items: center; justify-content: center; width: 250px; height: 250px; border-radius: 50%; background: $color-white; margin-top: 30px; cursor: pointer; }
    span {
        & { position: relative; display: inline-block; line-height: 24px; font-weight: 700; font-size: 14px; letter-spacing: 4px; text-transform: uppercase; color: $color-gray-3d; text-align: center; }
        &:before { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; background-color: $color-gray-3d; width: 0; transition: width 0.4s ease; z-index: 1; }
    }
    &:hover span:before { width: 95%; }
}
@media only screen and (max-width: $size-laptop) {
    .single-zoom-button {
        & { margin-top: 20px; width: 130px; height: 130px; }
        span { font-size: 12px; line-height: 20px; }
    }
}