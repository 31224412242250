/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3b904a');

@font-face {
    font-family: 'CeraPro';
    src: url('../assets/fonts/CeraPro-Medium/font.woff2') format('woff2'),
        url('../assets/fonts/CeraPro-Medium/font.woff') format('woff');
    font-weight: 700;
}
@font-face {
    font-family: 'CeraPro';
    src: url('../assets/fonts/CeraPro-Regular/font.woff2') format('woff2'),
        url('../assets/fonts/CeraPro-Regular/font.woff') format('woff');
    font-weight: 400;
}
@font-face {
    font-family: 'BlackerProDis';
    src: url('../assets/fonts/BlackerProDis-Lt/font.woff2') format('woff2'),
        url('../assets/fonts/BlackerProDis-Lt/font.woff') format('woff');
    font-weight: 400;
}

.font-cera {
    font-family: 'CeraPro', sans-serif;
    font-style: normal;
    font-weight: 400;
}

.font-blacker {
    font-family: 'BlackerProDis', serif;
    font-style: normal;
    font-weight: 400;
}

input,
textarea,
button,
select {
    @extend .font-cera;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @extend .font-blacker; font-weight: 400;
}
