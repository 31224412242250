.container-profile-intro { position: relative; }
.page-profile-intro { padding: 244px 0 150px; display: flex; flex-flow: column nowrap; justify-content: center; }
.profile-intro-title { @extend .elements-text-title; width: 920px; }
.profile-intro-text { @extend .elements-text-excerpt; width: 920px; padding-top: 30px; }
.profile-intro-image {
    & { height: #{'max(850px, 100vh)'}; position: relative; }
    img { position: absolute; top: 0; left: 0; width: 100%; height: 850px; transform: scale(0.83); transition: all 0.6s ease; object-fit: cover; }
    &--scrolled img { width: 100%; height: #{'max(850px, 100vh)'}; transform: scale(1); }
}
@media only screen and (max-width: $size-laptop) {
    .page-profile-intro { padding: 200px 0 100px; }
    .profile-intro-title  { width: 676px; }
    .profile-intro-text { width: 592px; }
}
@media only screen and (max-width: $size-tablet) {
    .page-profile-intro { padding: 170px 0 80px; }
    .profile-intro-title { width: 500px; }
    .profile-intro-text { width: 500px; }
}
@media only screen and (max-width: $size-phone) {
    .page-profile-intro { padding: 120px 0 60px; }
    .profile-intro-title { width: auto; }
    .profile-intro-text { width: auto; }
    .page-profile-intro-after {
        .profile-intro-text { padding-top: 60px; }
    }
}