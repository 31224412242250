.home-tour-bg { margin: 200px 272px 0 0; height: 700px; background: $color-gray-f7; }
.home-tour-image {
    & { height: 680px; margin: -530px 0 200px 136px; overflow: hidden; }
    img { height: 680px; }
}
.home-tour-content { position: relative; z-index: 10; }
.home-tour-content .layout { position: relative; }
.home-tour-content-inside { position: absolute; top: -458px; left: 136px; }
.home-tour-text { @extend .elements-text-title; }
.home-tour-button {
    & { display: flex; flex-flow: row nowrap; align-items: center; justify-content: center; width: 150px; height: 150px; border-radius: 50%; background: $color-white; margin-top: 30px; cursor: pointer; }
    span {
        & { position: relative; display: inline-block; line-height: 24px; font-weight: 700; font-size: 14px; letter-spacing: 4px; text-transform: uppercase; color: $color-gray-3d; }
        &:before { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; background-color: $color-gray-3d; width: 0; transition: width 0.4s ease; z-index: 1; }
    }
    &:hover span:before { width: 95%; }
}
.home-tour-popup {
    & { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; background-color: $color-white; opacity: 0; transform: scale(0); visibility: hidden; transition: all 0.5s ease; }
    &--open { opacity: 1; transform: scale(1); visibility: visible; }
    iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}
.home-tour-popup-close {
    & { position: absolute; top: 50px; right: 50px; width: 48px; height: 48px; background-color: $color-gold; cursor: pointer; transition: background-color 0.4s ease; }
    &::before,
    &::after { content: ''; position: absolute; left: 14px; top: 23px; height: 1px; width: 20px; background: $color-white; transition: transform 0.4s ease; }
    &:hover::before { transform: rotate(45deg); }
    &:hover::after { transform: rotate(-45deg); }
}
@media only screen and (max-width: $size-wide) {
    .home-tour-bg { margin: 180px 80px 0 0; }
    .home-tour-content-inside { left: 110px; }
    .home-tour-image { margin: -530px 0 180px 80px; }
}
@media only screen and (max-width: $size-desktop) {
    .home-tour-bg { margin: 160px 70px 0 0; height: 560px; }
    .home-tour-image {
        & { height: 560px; margin: -400px 0 160px 70px; }
        img { height: 560px; }
    }
    .home-tour-content-inside { top: -328px; left: 100px; }
}
@media only screen and (max-width: $size-laptop) {
    .home-tour-bg { margin: 140px 62px 0 0; height: 430px; }
    .home-tour-image {
        & { height: 400px; margin: -290px 0 140px 62px; }
        img { height: 400px; }
    }
    .home-tour-content-inside { top: -250px; left: 120px; }
    .home-tour-button {
        & { margin-top: 20px; width: 130px; height: 130px; }
        span { font-size: 12px; line-height: 20px; }
    }
}
@media only screen and (max-width: $size-tablet) {
    .home-tour-bg { margin: 120px 46px 0 0; height: 550px; }
    .home-tour-image {
        & { height: 550px; margin: -430px 0 120px 46px; }
        img { height: 550px; }
    }
    .home-tour-content-inside { top: -390px; left: 120px; }
}
@media only screen and (max-width: $size-phone) {
    .home-tour-bg { display: none; margin: 0; height: 0; }
    .home-tour-image {
        & { height: 430px; margin: 60px 25px; }
        img { height: 430px; }
    }
    .home-tour-content-inside { top: 100px; left: 50%; width: 175px; margin-left: -88px; }
    .home-tour-button {
        & { width: 70px; height: 70px; margin: 48px auto 0;}
        span { font-size: 9px; line-height: 16px; letter-spacing: 2px; }
    }
    .home-tour-popup-close { top: 20px; right: 20px; }
}