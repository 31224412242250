@keyframes heartbeat {
    to { transform: scale(0.8); }
}

.container-profile-founder { padding: 0 0 200px; position: relative; }
.container-profile-founder-bg {
    & { position: absolute; top: 0; left: 0; z-index: -1; width: 100%; background-color: $color-gray-f7; }
    .layout { height: 692px; }
}
.page-profile-founder { display: flex; flex-flow: row nowrap; justify-content: space-between; }
.profile-founder-image {
    & { padding-top: 380px; width: 615px; }
    img { width: 100%; vertical-align: middle; }
}
.profile-founder-content { width: 648px; padding-top: 100px; }
.profile-founder-title {
    & { @extend .elements-text-title; }
    .heart { font-style: normal; font-size: 32px; display: inline-block; animation: heartbeat 1s infinite alternate; }
}
.profile-founder-text { @extend .elements-text-paragraph; width: 88%; padding-top: 20px; }
@media only screen and (max-width: $size-wide) {
    .profile-founder-content { width: 585px; }
    .profile-founder-image { padding-top: 320px; }
}
@media only screen and (max-width: $size-desktop) {
    .container-profile-founder { padding-bottom: 150px; }
    .container-profile-founder-bg {
        .layout { height: 620px; }
    }
    .profile-founder-image { padding-top: 290px; width: 550px; }
    .profile-founder-content { width: 550px; padding-top: 80px; }
}
@media only screen and (max-width: $size-laptop) {
    .container-profile-founder { padding-bottom: 100px; }
    .container-profile-founder-bg {
        .layout { height: 500px; }
    }
    .profile-founder-image { padding-top: 260px; width: 440px; }
    .profile-founder-content { width: 420px; padding-top: 60px; }
}
@media only screen and (max-width: $size-tablet) {
    .container-profile-founder-bg {
        .layout { height: 500px; }
    }
    .page-profile-founder { flex-flow: column nowrap; align-items: center; }
    .profile-founder-image { width: 500px; order: 2; padding-top: 50px; }
    .profile-founder-content { width: 500px; padding-top: 0; order: 1; }
}
@media only screen and (max-width: $size-phone) {
    .container-profile-founder { padding-bottom: 60px; }
    .container-profile-founder-bg {
        .layout { height: 500px; }
    }
    .profile-founder-image { width: 240px; padding-top: 30px; }
    .profile-founder-content { width: 240px; }
    .profile-founder-title {
        span { font-size: 20px; }
    }
}