.container-projects-list { position: relative; }
.page-projects-list { position: relative; z-index: 1; }
.projects-list-header { padding: 300px 0 275px; width: 1056px; }
.projects-list-title { @extend .elements-text-title; }
.projects-list-items { padding-bottom: 200px; }
.projects-list-row {
    & { display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: flex-start; padding-bottom: 100px; position: relative; }
    &:last-child { padding-bottom: 0; }
    &--single:before { content: ''; position: absolute; top: -240px; bottom: -120px; left: 50%; width: 100vw; transform: translate(-50%, 0); background: $color-gray-f7; }
    &--double { z-index: 2; }
}
.projects-list-item {
    & { width: 100%; color: $color-gray-3d; }
    &:hover { color: $color-gold; }
    &--hor { width: 648px; }
    &--ver { width: 512px; }
}
.projects-list-image {
    & { position: relative; z-index: 1; }
    img { width: 100%; vertical-align: middle; }
    .projects-list-cover {
        & { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(219, 184, 100, 0.4); opacity: 0; transition: opacity 0.4s ease; }
        .projects-list-item:hover & { opacity: 1; }
    }
}
.projects-list-text { @extend .elements-text-excerpt; height: 62px; position: relative; }
.projects-list-name {
    & { position: absolute; bottom: 0; left: 0; transition: transform 0.4s ease;}
    .projects-list-item:hover & { transform: translate(0, -62px); }
}
.projects-list-hover {
    & { position: absolute; bottom: 0; left: 0; color: $color-gold; transform: translate(0, -62px); transition: transform 0.4s ease; }
    .projects-list-item:hover & { transform: translate(0, 0); }
}
.projects-list-line {
    & { position: absolute; top: 0; left: 0; height: 4px; width: 100%; background-color: $color-gold; transform: scaleX(0); transition: transform 0.4s ease; transform-origin: left center; }
    .projects-list-item:hover & { transform: scaleX(1); }
}
@media only screen and (max-width: $size-wide) {
    .projects-list-header { width: 950px; }
    .projects-list-item {
        &--hor { width: 620px; }
        &--ver { width: 512px; }
    }
}
@media only screen and (max-width: $size-desktop) {
    .projects-list-item {
        &--hor { width: 588px; }
        &--ver { width: 464px; }
    }
}
@media only screen and (max-width: $size-laptop) {
    .projects-list-header { padding: 200px 0; width: 676px; }
    .projects-list-row {
        & { padding-bottom: 80px; }
        &:last-child { padding-bottom: 0; }
        &--single:before { top: -180px; bottom: -80px; }
    }
    .projects-list-item {
        &--hor { width: 440px; }
        &--ver { width: 360px; }
    }
    .projects-list-text { height: 46px}
}
@media only screen and (max-width: $size-tablet) {
    .projects-list-header { padding: 170px 0 80px; width: 550px; margin: 0 auto; }
    .projects-list-row { flex-flow: column nowrap; }
    .projects-list-items { padding-bottom: 100px; }
    .projects-list-item {
        &--hor { width: 414px; }
        &--ver { width: 328px; }
        & + & { padding-top: 80px; }
        &:nth-child(2) { align-self: flex-end; }
    }
}
@media only screen and (max-width: $size-phone) {
    .projects-list-header { padding: 120px 0 60px; width: auto; margin: 0; }
    .projects-list-items { padding-bottom: 60px; }
    .projects-list-row {
        & { padding-bottom: 30px; }
        &:last-child { padding-bottom: 0; }
        &--single:before { top: -80px; bottom: -50px; }
    }
    .projects-list-item {
        &--hor { width: auto; }
        &--ver { width: auto; }
        & + & { padding-top: 30px; }
    }
    .projects-list-text { height: 30px; }
}