.container-profile-about { padding: 200px 0; position: relative; }
.container-profile-about-bg {
    & { position: absolute; bottom: 0; left: 0; z-index: -1; width: 100%; background-color: $color-gray-f7; }
    .layout { height: 512px; }
}
.page-profile-about { display: flex; flex-flow: row nowrap; justify-content: space-between; }
.profile-about-col { }
.profile-about-col-1 { width: 648px; padding-top: 100px; }
.profile-about-col-2 { width: 512px; }
.profile-about-title { @extend .font-cera; @extend .elements-text-excerpt; }
.profile-about-text { @extend .elements-text-paragraph; padding-top: 16px; }
.profile-about-image {
    img { width: 100%; vertical-align: middle; }
    .profile-about-col-1 & { padding-top: 60px; }
    .profile-about-col-2 & { padding-bottom: 60px; }
}
@media only screen and (max-width: $size-desktop) {
    .container-profile-about { padding: 150px 0 200px; }
    .profile-about-col-1 { width: 588px; }
    .profile-about-col-2 { width: 464px; }
}
@media only screen and (max-width: $size-laptop) {
    .container-profile-about { padding: 100px 0 150px; }
    .profile-about-col-1 { width: 440px; padding-top: 80px; }
    .profile-about-col-2 { width: 360px; }
    .profile-about-image {
        .profile-about-col-1 & { padding-top: 40px; }
        .profile-about-col-2 & { padding-bottom: 40px; }
    }
}
@media only screen and (max-width: $size-tablet) {
    .container-profile-about { padding: 80px 0 150px; }
    .container-profile-about-bg {
        .layout { height: 880px; }
    }
    .page-profile-about { display: block; }
    .profile-about-col-1 {
        & { width: 500px; padding-top: 0; }
        .profile-about-image { padding-top: 60px; width: 414px; }
    }
    .profile-about-col-2 {
        & { padding-top: 100px; width: auto; display: flex; flex-flow: row nowrap; justify-content: space-between; }
        .profile-about-block { width: 328px; order: 1; }
        .profile-about-image { padding-bottom: 0; width: 328px; order: 2; }
        .profile-about-text { width: 240px; }
    }
}
@media only screen and (max-width: $size-phone) {
    .container-profile-about { padding: 60px 0; }
    .container-profile-about-bg {
        & { top: 200px; }
        .layout { height: auto; }
    }
    .profile-about-col { display: flex; flex-flow: column nowrap; }
    .profile-about-col-1 {
        & { width: auto; }
        .profile-about-block { order: 2; z-index: 1; position: relative; top: -30px; left: -25px; background: red; margin-right: 25px; padding: 30px 48px 30px 25px; background: $color-white; }
        .profile-about-image { order: 1; padding-top: 0; width: auto; position: relative; left: -25px; width: -25px; width: calc(100% + 50px); }
    }
    .profile-about-col-2 {
        & { padding-top: 30px; flex-flow: column nowrap; justify-content: flex-start; }
        .profile-about-block { order: 2; width: auto; padding-bottom: 60px; z-index: 1; position: relative; top: -30px; right: -25px; background: red; margin-left: 25px; padding: 30px 25px 30px 48px; background: $color-white; }
        .profile-about-image { order: 1; padding-bottom: 0; width: auto; position: relative; left: -25px; width: -25px; width: calc(100% + 50px); }
        .profile-about-text { width: auto; }
    }
}