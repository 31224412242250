.container-projects-info { background-color: $color-gray-f7; }
.page-projects-info { width: 784px; margin: 0 auto; padding: 210px 0; }
.projects-info-title { @extend .elements-text-title; }
@media only screen and (max-width: $size-desktop) {
    .page-projects-info { width: 620px; padding: 180px 0; }
}
@media only screen and (max-width: $size-laptop) {
    .page-projects-info { width: 440px; padding: 128px 0; }
}
@media only screen and (max-width: $size-tablet) {
    .page-projects-info { width: 500px; padding: 80px 0; }
}
@media only screen and (max-width: $size-phone) {
    .page-projects-info { width: 240px; padding: 80px 0; }
}