.container-footer { position: relative; background-color: $color-gray-32; color: $color-white; overflow: hidden; }
.page-footer { display: flex; flex-flow: column nowrap; justify-content: space-between; position: relative; min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100); }

.footer-link { @extend .element-link; color: $color-white; }

.footer-header { padding: 100px 0; margin: auto 0; }
.footer-header-title { @extend .elements-text-title; }
.footer-header-button {
    & { padding-top: 30px; width: 310px; }
    a { display: block; text-align: center; height: 54px; line-height: 52px; font-size: 12px; letter-spacing: 3px; text-transform: uppercase; color: $color-gold; position: relative; border: 1px solid $color-gold; box-sizing: border-box; }
    a:before { content: ''; position: absolute; top: -1px; left: -1px; bottom: -1px; right: -1px; box-sizing: border-box; border: 2px solid $color-white; transition: opacity 0.4s ease; opacity: 0; }
    a:hover:before { opacity: 1; }
}

.footer-content { width: 784px; padding: 0 0 100px; display: grid; grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(2, 1fr); grid-column-gap: 32px; grid-row-gap: 50px; font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; font-weight: 700; color: $color-gray-f7; }
.footer-content-section {
    & { transform: translate(0, 100px); opacity: 0; transition: transform 0.8s ease, opacity 0.8s ease; }
    &:nth-child(1) { transition-delay: 0.1s; }
    &:nth-child(2) { transition-delay: 0.2s; }
    &:nth-child(3) { transition-delay: 0.3s; }
    &:nth-child(4) { transition-delay: 0.4s; }
    &:nth-child(5) { transition-delay: 0.6s; }
    .footer-content--show & { transform: translate(0, 0); opacity: 1; }
}
.footer-content-address { grid-area: 1 / 1 / 2 / 2; font-style: normal; }
.footer-content-hours { grid-area: 1 / 2 / 2 / 3; }
.footer-content-contact { grid-area: 1 / 3 / 2 / 4; }
.footer-content-copyright {
    & { grid-area: 2 / 1 / 3 / 3; }
    span { color: $color-gold; }
}
.footer-content-social { grid-area: 2 / 3 / 3 / 4; }
.footer-logo-awi {
    & { position: absolute; bottom: 100px; right: 0; width: 240px; height: 86px; transform: translate(0, 100px); opacity: 0; transition: transform 0.8s ease, opacity 0.8s ease; transition-delay: 0.5s}
    &--show { transform: translate(0, 0); opacity: 1; }
}
@media only screen and (max-width: $size-laptop) {
    .footer-header { padding: 60px 0; }
    .footer-content { padding: 0 0 60px; width: 520px; grid-template-columns: repeat(2, 1fr); grid-template-rows: repeat(3, 1fr); grid-column-gap: 40px; grid-row-gap: 40px; font-size: 11px; line-height: 24px; }
    .footer-content-address { grid-area: 1 / 1 / 2 / 2; }
    .footer-content-hours { grid-area: 1 / 2 / 2 / 3; }
    .footer-content-contact { grid-area: 2 / 1 / 3 / 2; }
    .footer-content-copyright { grid-area: 3 / 1 / 4 / 3; }
    .footer-content-social { grid-area: 2 / 2 / 3 / 3; }

    .footer-logo-awi { bottom: 60px; }
}
@media only screen and (max-width: $size-tablet) {
    .footer-header { padding: 50px 0; }
    .footer-content { padding: 0 0 50px; }
    .footer-logo-awi { bottom: 50px; width: 151px; height: 54px; }
}
@media only screen and (max-width: $size-phone) {
    .footer-header { padding: 40px 0 20px; }
    .footer-header-button {
        & { padding-top: 20px; width: 204px; }
        a { height: 44px; line-height: 42px; font-size: 9px; letter-spacing: 2px; }
    }
    .footer-content { width: auto; padding: 20px 0; display: block; font-size: 9px; line-height: 22px; }
    .footer-content-address { padding-bottom: 15px; }
    .footer-content-hours { display: none; }
    .footer-content-contact { padding-bottom: 115px; }
    .footer-content-social { display: none; }
    .footer-content-copyright {
        br { display: none; }
        a { display: inline-block; margin-left: 4px; }
    }
    .footer-logo-awi { bottom: 84px; right: auto; left: 0; width: 128px; height: 46px; }
}
