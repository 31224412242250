.single-popup {
    & { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 1000; background-color: $color-white; opacity: 0; transform: scale(0); visibility: hidden; transition: all 0.5s ease; }
    &--open { opacity: 1; transform: scale(1); visibility: visible; }
    iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}
.single-popup-close {
    & { position: absolute; top: 50px; right: 50px; width: 48px; height: 48px; background-color: $color-gold; cursor: pointer; transition: background-color 0.4s ease; }
    &::before,
    &::after { content: ''; position: absolute; left: 14px; top: 23px; height: 1px; width: 20px; background: $color-white; transition: transform 0.4s ease; }
    &:hover::before { transform: rotate(45deg); }
    &:hover::after { transform: rotate(-45deg); }
}
@media only screen and (max-width: $size-phone) {
    .single-popup-close { top: 20px; right: 20px; }
}