.container-menu {
    & { position: fixed; top: 0; left: 0; width: 100%; height: 100vh; height: calc(var(--vh, 1vh) * 100); background-color: $color-gold; z-index: 200; transform: translate(0, -100%); transition: transform 0.4s ease; overflow: auto; overflow: hidden; }
    &.open { transform: translate(0, 0); }
    &-home-2 { background-color: $color-triangle-2; }
    &-home-3 { background-color: $color-triangle-3; }
    &-home-4 { background-color: $color-triangle-4; }
}
.page-menu { display: flex; flex-flow: column nowrap; justify-content: space-between; align-items: flex-start; box-sizing: border-box; padding: 50px 0 100px; width: 100%; height: 100vh; height: calc(var(--vh, 1vh) * 100); }

.menu-link { @extend .element-link; }

.menu-header { flex: 0 0 52px; display: flex; width: 100%; flex-flow: row nowrap; justify-content: space-between; align-items: center; }
.menu-header-close {
    & { width: 48px; height: 48px; background: $color-white; position: relative; cursor: pointer; }
    &::before,
    &::after { content: ''; position: absolute; left: 14px; top: 23px; height: 1px; width: 20px; background: $color-gold; transition: transform 0.4s ease; }
    &:hover::before { transform: rotate(45deg); }
    &:hover::after { transform: rotate(-45deg); }
}
.menu-header-social { font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; font-weight: 700; color: $color-gray-3d; }

.menu-footer { flex: 0 0 52px; display: flex; width: 100%; flex-flow: row nowrap; align-items: center; font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; font-weight: 700; color: $color-gray-3d; position: relative; z-index: 10; }
.menu-footer-address { font-style: normal; margin-right: 50px; }
.menu-footer-hours { margin-right: 50px; }
.menu-footer-contact { }

.menu-nav { flex: 1 1 0; margin: 50px 0; width: 100%; position: relative; }
.menu-image { display: none; }
.menu-drag { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); margin-top: 96px; }
.menu-items { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); white-space: nowrap; z-index: 2; }
.menu-item {
    & { @extend .font-blacker; display: inline-block; font-size: 140px; line-height: 150px; letter-spacing: -1px; color: $color-gray-3d; margin: 0 25px; transition: color 0.4s ease; position: relative; }
    &:hover { color: $color-white; }
}
@media only screen and (min-width: $size-laptop) and (hover: hover) {
    .menu-image {
        & { display: block; position: absolute; top: 0; height: 100%; left: 50%; transform: translate(-50%, 0); }
        img { height: 100%; position: relative; z-index: 0; }
        &::before,
        &::after { content: ''; position: absolute; top: -1%; left: -1%; width: 102%; height: 102%; background: $color-gold; z-index: 1; }
        &::before { transform: translate(0, 0); transition: transform 1s ease; }
        &::after { transform: translate(0, 0); transition: transform 0s ease 1s; }
        &.forward { z-index: 1; }
        &.hover::before { transform: translate(-102%, 0); transition: transform 0s ease; }
        &.hover::after { transform: translate(0, 102%); transition: transform 1s ease; }
    }
    .menu-item {
        &::after { content: ''; position: absolute; left: 0; bottom: -28px; height: 2px; width: 0; background: $color-white; transition: width 0.4s ease; }
        &:hover::after { width: 100%; }
    }
}
@media only screen and (max-width: $size-laptop) {
    .page-menu { padding: 50px 0 66px; }
    .menu-header { flex: 0 0 48px; }
    .menu-header-social { font-size: 11px; line-height: 24px; }
    .menu-footer { flex: 0 0 48px; font-size: 11px; line-height: 24px; }
    .menu-item { font-size: 84px; line-height: 96px; }
}
@media only screen and (max-width: $size-tablet) {
    .menu-footer-address { margin-right: 32px; }
    .menu-footer-hours { margin-right: 32px; }
}
@media only screen and (max-width: $size-phone) {
    .page-menu { padding: 20px 0; }
    .menu-header { flex: 0 0 44px; }
    .menu-header-close {
        & { width: 34px; height: 34px; }
        &::before,
        &::after { left: 10px; top: 16px; width: 14px; }
    }
    .menu-header-social { font-size: 9px; line-height: 22px; letter-spacing: 1px; }

    .menu-footer { flex: 0 0 98px; display: block; font-size: 9px; line-height: 22px; letter-spacing: 1px; }
    .menu-footer-address { margin-right: 0; margin-bottom: 10px; }
    .menu-footer-hours {
        & { margin-right: 0; position: absolute; left: 100%; bottom: -6px; white-space: nowrap; transform-origin: left center; transform: rotate(-90deg); }
        br { display: none; }
        span { display: inline-block; margin-left: 4px; }
        span:first-child { margin-left: 0; }
    }

    .menu-nav { flex: 1 1 0; margin: auto 0; width: 100%; position: relative; display: flex; flex-flow: column nowrap; justify-content: center; }
    .menu-image { display: none; }
    .menu-drag { display: none; }
    .menu-items { position: static; top: auto; left: auto; transform: none !important; }
    .menu-item { display: block; font-size: 26px; line-height: 42px; margin: 0; }
}
