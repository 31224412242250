.container-contact-form { background-color: $color-gray-f7; }
.page-contact-form { padding: 200px 0; display: flex; flex-flow: row nowrap; justify-content: space-between; }
.contact-form-info { width: 512px; }
.contact-form-title { @extend .elements-text-title; }
.contact-form-text { padding-top: 30px; @extend .elements-text-excerpt; }

.contact-form {
    & { width: 784px; }

    label { @extend .elements-text-excerpt; display: block; padding-bottom: 10px; }
    input,
    textarea { width: 100%; margin: 0; border: 0; background: $color-gray-f0; box-sizing: border-box; font-size: 16px; transition: color 0.4s ease, background-color 0.4s ease; vertical-align: middle; -webkit-appearance: none; appearance: none; border-radius: 0; }
    input { padding: 0 10px; height: 54px; }
    textarea { height: 160px; padding: 10px; line-height: 20px; box-sizing: border-box; resize: none; }
    input:focus,
    textarea:focus { background-color: $color-white; color: $color-gold; }

    .input-row { padding-bottom: 10px; display: flex; flex-flow: row nowrap; justify-content: space-between; }
    .input-box {
        & { width: 376px; position: relative; }
        &.with-error:after { content: ''; position: absolute; bottom: 0; left: 0; width: 100%; height: 1px; background-color: $color-red; }
    }
    .textarea-box { width: 648px; }
    .input-box-error { position: absolute; top: 100%; right: 0; color: $color-red; font-size: 9px; line-height: 15px; text-transform: uppercase; letter-spacing: 1px; }
    .input-bottom { display: flex; flex-flow: row nowrap; }
    .button-box { width: 136px; padding-top: 42px; }
    .button-box button {
        & { width: 136px; height: 160px; background: $color-gold; padding: 0; margin: 0; border: 0; }
        span {
            & { position: relative; display: inline-block; line-height: 24px; font-weight: 700; font-size: 14px; letter-spacing: 4px; text-transform: uppercase; color: $color-gray-3d; }
            &:before { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; background-color: $color-gray-3d; width: 0; transition: width 0.4s ease; z-index: 1; }
        }
        &:hover span:before { width: 95%; }
    }

    .input-footer { padding-top: 10px; }
    .input-note {
        & { font-weight: 700; font-size: 12px; line-height: 20px; letter-spacing: 2px; text-transform: uppercase; }
        &.error { color: $color-red; }
    }
}
@media only screen and (max-width: $size-wide) {
    .contact-form-info { width: 400px; }
    .contact-form {
        & { width: 840px; }
        .input-box { width: 400px; }
        .textarea-box { width: 706px; }
    }
}
@media only screen and (max-width: $size-desktop) {
    .contact-form-info { width: 360px; }
    .contact-form {
        & { width: 750px; }
        .input-box { width: 360px; }
        .textarea-box { width: 614px; }
    }
}
@media only screen and (max-width: $size-laptop) {
    .page-contact-form { padding: 120px 0; }
    .contact-form-info { width: 284px; }
    .contact-form-text { padding-top: 20px; }
    .contact-form {
        & { width: 592px; }
        .input-box { width: 284px; }
        .textarea-box { width: 456px; }
        textarea { height: 120px; }
        .button-box { padding-top: 36px; }
        .button-box button {
            & { height: 120px; }
            span { font-size: 12px; line-height: 20px; letter-spacing: 2px; }
        }
    }
}
@media only screen and (max-width: $size-tablet) {
    .page-contact-form { display: block; padding: 100px 0; }
    .contact-form-info { width: auto; padding-bottom: 80px; }
    .contact-form {
        & { width: auto; }
        .input-box { width: 326px; }
        .textarea-box { width: 540px; }
    }
}
@media only screen and (max-width: $size-phone) {
    .page-contact-form { padding: 50px 0; }
    .contact-form-info { padding-bottom: 50px; }
    .contact-form {
        .input-row { padding-bottom: 0; display: block; }
        .input-box { width: auto; margin-bottom: 10px; }
        input { padding: 0 8px; height: 40px; }
        .input-bottom { display: block; }
        .textarea-box { width: auto; margin-bottom: 0; }
        textarea { height: 96px; padding: 8px; }
        .button-box { width: auto; padding-top: 30px; }
        .button-box button {
            & { width: 100%; height: 52px; font-size: 12px; letter-spacing: 1px; }
            span { font-size: 12px; line-height: 20px; letter-spacing: 2px; }
        }
        .input-note {
            & { font-size: 10px; line-height: 16px; letter-spacing: 1px; }
        }
    }
}