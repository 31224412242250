.page-cover { position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; transform: translate(0, 100%); }
.page-cover.enter { transform: translate(0, 0); transition: transform 0.6s ease; }
.page-cover.stay { transform: translate(0, 0); }
.page-cover.leave { transform: translate(0, 100%); transition: transform 0.6s ease; }

.page-cover-start { background: $color-gray-3d; z-index: 10000; }
.page-cover-end { background: $color-gray-3d; z-index: 10000; }
.page-cover-text {
    & { background: $color-gray-3d; z-index: 9999; }
    &--home { background: $color-gold url('../assets/home-transiiton.jpg') no-repeat center center; background-size: cover; }
    &--profile { background: $color-gold url('../assets/profile-transiiton.jpg') no-repeat center center; background-size: cover; }
    &--projects { background: $color-gold url('../assets/projects-transiiton.jpg') no-repeat center center; background-size: cover; }
    &--contact { background: $color-gold url('../assets/contact-transiiton.jpg') no-repeat center center; background-size: cover; }
    span { @extend .font-blacker; display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 200px; line-height: 220px; color: $color-gold; }
}
@media only screen and (max-width: $size-tablet) {
    .page-cover-text span { font-size: 120px; line-height: 140px; }
}
@media only screen and (max-width: $size-phone) {
    .page-cover-text span { font-size: 70px; line-height: 80px; }
}