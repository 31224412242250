.container-profile-press { padding: 0 0 200px; }
.page-profile-press { }
.profile-press-title { @extend .elements-text-title; }
.profile-press-text { @extend .elements-text-excerpt; width: 512px; padding-top: 30px; }
.profile-press-list { padding-top: 150px; display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: center; }
.profile-press-item { }
@media only screen and (max-width: $size-desktop) {
    .container-profile-press { padding: 0 0 150px; }
}
@media only screen and (max-width: $size-laptop) {
    .container-profile-press { padding: 0 0 100px; }
    .profile-press-text { width: 440px; padding-top: 20px; }
    .profile-press-list { padding-top: 100px; }
    .profile-press-item {
        img { max-width: 200px; }
    }
}
@media only screen and (max-width: $size-tablet) {
    .profile-press-text { width: 414px; padding-top: 10px; }
    .profile-press-list { padding-top: 80px; }
    .profile-press-item {
        img { max-width: 180px; }
    }
}
@media only screen and (max-width: $size-phone) {
    .container-profile-press { padding: 0 0 60px; }
    .profile-press-text { width: auto; }
    .profile-press-list { padding-top: 0; flex-flow: column nowrap; justify-content: flex-start; }
    .profile-press-item {
        & { padding-top: 60px; }
        img { max-width: 128px; }
        &:first-child { align-self: flex-start; }
        &:last-child { align-self: flex-end; }
    }
}