.container-header { position: fixed; top: 0; left: 0; width: 100%; z-index: 99; }
.page-header { padding-top: 50px; display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: center; }
.header-logo {
    .header-logo-text { fill: $color-gray-32; transition: fill 0.4s ease; }
    .header-logo-triangle { fill: $color-gold; transition: fill 0.4s ease; }
    .header-logo-triangle-home-2 { fill: $color-triangle-2; }
    .header-logo-triangle-home-3 { fill: $color-triangle-3; }
    .header-logo-triangle-home-4 { fill: $color-triangle-4; }
    &--footer .header-logo-text { fill: $color-white; }
    svg { width: 144px; height: 44px; vertical-align: middle; }
}
.header-trigger {
    & { width: 48px; height: 48px; background-color: $color-gold; position: relative; cursor: pointer; transition: background-color 0.4s ease; }
    &:before,
    &:after { content: ''; position: absolute; left: 14px; height: 1px; width: 20px; background: $color-white; transition: transform 0.4s ease; }
    &:before { top: 20px; }
    &:after { top: 27px; }
    &:hover {
        &:before { transform: translate(0, 4px); }
        &:after { transform: translate(0, -3px); }
    }
}
.header-trigger-home-2 { background-color: $color-triangle-2; }
.header-trigger-home-3 { background-color: $color-triangle-3; }
.header-trigger-home-4 { background-color: $color-triangle-4; }
@media only screen and (max-width: $size-phone) {
    .page-header { padding-top: 20px; }
    .header-logo {
        svg { height: 20px; width: 72px; }
    }
    .header-trigger {
        & { width: 34px; height: 34px; }
        &:before,
        &:after { left: 10px; width: 14px; }
        &:before { top: 14px; }
        &:after { top: 19px; }
    }
}