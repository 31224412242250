.container-single-next { display: block; background: $color-gray-f7; }
.page-single-next { display: flex; flex-flow: row nowrap; }
.single-next-image {
    & { width: 50%; height: 43.75vw; object-fit: cover; vertical-align: middle; opacity: 0.5; transition: opacity 0.4s ease; }
    &--hover { opacity: 1; }
}
.single-next-box { display: block; width: 50%; display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; color: $color-gray-3d; }
.single-next-title {
    & { @extend .font-blacker; @extend .elements-text-title; }
    span { position: relative; }
    span:before { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; width: 100%; background: $color-gray-d8; transition: width 0.4s ease; z-index: 1; }
    span:after { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; width: 0; background: $color-gray-32; transition: width 0.4s ease; z-index: 1; }
    .single-next-box:hover & span:after { width: 100%; }
}
@media only screen and (max-width: $size-phone) {
    .page-single-next { display: block; }
    .single-next-image { width: 100%; height: auto; }
    .single-next-box { width: 100%; padding: 64px 0; }
}