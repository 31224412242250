.container-single-images { }
.page-single-images { padding-top: 200px; }
.single-images-row {
    & { padding-bottom: 200px; position: relative; z-index: 2; }
    &--flex { display: flex; flex-flow: row nowrap; }
    &--bg { z-index: 1; }
    &--bg:before { content: ''; position: absolute; top: -240px; bottom: -120px; left: 50%; width: 100vw; transform: translate(-50%, 0); background: $color-gray-f7; }
}
.single-images-single {
    img { width: 100%; vertical-align: middle; }
}
.single-images-full {
    img { width: 100%; vertical-align: middle; }
}
.single-images-half {
    & { width: 50%; display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; position: relative; z-index: 3; }
    img { max-width: 100%; vertical-align: middle; }
    &--small {
        img { max-width: 60%; }
    }
}
.single-images-text { width: 512px; margin: 0 auto; @extend .elements-text-title; }
.single-images-row--double {
    .single-images-half {
        & { box-sizing: border-box; }
        &:first-child { padding-right: 20px; }
        &:last-child { padding-left: 20px; }
    }
}
@media only screen and (max-width: $size-wide) {
    .page-single-images { padding-top: 150px; }
    .single-images-row { padding-bottom: 150px; }
}
@media only screen and (max-width: $size-desktop) {
    .single-images-text { width: 420px; }
}
@media only screen and (max-width: $size-laptop) {
    .page-single-images { padding-top: 100px; }
    .single-images-row {
        & { padding-bottom: 100px; }
        &--bg:before { top: -180px; bottom: -80px; }
    }
    .single-images-text { width: 284px; }

}
@media only screen and (max-width: $size-tablet) {
    .page-single-images { padding-top: 80px; }
    .single-images-row { padding-bottom: 80px; }
    .single-images-text { width: 240px; }
}
@media only screen and (max-width: $size-phone) {
    .page-single-images { padding-top: 60px; }
    .single-images-row {
        & { padding-bottom: 60px; }
        &--flex { display: block; }
        &--bg:before { top: -80px; bottom: -50px; }
    }
    .single-images-half {
        & { display: block; width: 100%; }
        &--small {
            img { max-width: 100%; }
        }
    }
    .single-images-row--double {
        .single-images-half {
            & { box-sizing: border-box; }
            &:first-child { padding-right: 96px; padding-bottom: 60px; }
            &:last-child { padding-left: 96px; text-align: right; }
        }
    }
    .single-images-text { width: 175px; margin: 0 auto; padding: 85px 0; }
}