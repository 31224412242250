.elements-text-title {
    & { font-size: 52px; line-height: 70px; }
    .gold { color: $color-gold; }
}
.elements-text-excerpt { font-size: 16px; line-height: 32px; font-weight: 700; letter-spacing: 1px; text-transform: uppercase; }
.elements-text-paragraph { font-size: 16px; line-height: 32px; }
@media only screen and (max-width: $size-laptop) {
    .elements-text-title { font-size: 36px; line-height: 48px; }
    .elements-text-excerpt { font-size: 12px; line-height: 26px; }
    .elements-text-paragraph { font-size: 14px; line-height: 28px; }
}
@media only screen and (max-width: $size-tablet) {
    .elements-text-title { font-size: 34px; line-height: 44px; }
}
@media only screen and (max-width: $size-phone) {
    .elements-text-title { font-size: 22px; line-height: 30px; }
    .elements-text-excerpt { font-size: 10px; line-height: 20px; }
    .elements-text-paragraph { font-size: 11px; line-height: 20px; }
}

.element-lines-bg { background-color: transparent; background-image: linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line); background-position: 0%, 16.666666666%, 33.333333333%, 50%, 66.666666666%, 83.333333333%, 100%; background-repeat: repeat-y; background-size: 1px auto; }
@media screen and (max-width: $size-phone) {
    .element-lines-bg { background-image: linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line), linear-gradient(180deg, $color-gray-line, $color-gray-line); background-position: 25%, 50%, 75%; }
}
@media  (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    .element-lines-bg { background-size: 0.5px auto; }
}

.element-link {
    & { display: inline-block; position: relative; color: $color-gray-3d; font-weight: 700; }
    &:before,
    &:after { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; }
    &:before { width: 100%; background-color: $color-gray-53; }
    &:after { width: 0; background-color: $color-white; transition: width 0.4s ease; z-index: 1; }
    @at-root {
        a#{&}:hover:after,
        a:hover > span#{&}:after { width: 100%; }
    }
}
.element-link-reverse-line {
    &:before { background-color: $color-gray-f7; }
    &:after { background-color: $color-gray-53; }
}

.element-title-in-view {
    span { display: inline-block; transform: translate(0, 20px); opacity: 0; transition: transform 0.8s ease, opacity 0.8s ease; }
    .in-view & span { transform: translate(0, 0); opacity: 1; }
    @for $i from 0 through 50 {
        span:nth-of-type(#{$i}) { transition-delay: 0.15s * $i; }
    }
}
.element-in-view {
    & { transform: translate(0, 20px); opacity: 0; transition: transform 1s ease, opacity 1s ease; }
    &.in-view,
    .in-view & { transform: translate(0, 0); opacity: 1; }
}
@for $i from 0 through 50 {
    .element-in-view-#{$i} { transition-delay: 0.15s * $i; }
}