.container-contact-header {  }
.page-contact-header { padding: 300px 0 200px; }
.contact-header-title {
    & { @extend .elements-text-title; width: 784px; }
    span { color: $color-gold; }
}
.contact-header-link { @extend .element-link; @extend .element-link-reverse-line; }
.contact-header-company { padding-top: 60px; display: flex; width: 100%; flex-flow: row nowrap; align-items: center; font-size: 12px; line-height: 26px; letter-spacing: 2px; text-transform: uppercase; font-weight: 700; color: $color-gray-3d; }
.contact-header-address { font-style: normal; margin-right: 50px; }
.contact-header-hours { margin-right: 50px; }
.contact-header-phone-email {  }
@media only screen and (max-width: $size-laptop) {
    .page-contact-header { padding: 200px 0 100px; }
    .contact-header-title { width: 520px; }
}
@media only screen and (max-width: $size-tablet) {
    .contact-header-company { display: block; }
    .contact-header-address { margin-right: 0; margin-bottom: 30px; }
    .contact-header-hours { margin-right: 0; margin-bottom: 30px; }
}
@media only screen and (max-width: $size-phone) {
    .page-contact-header { padding: 100px 0 84px; }
    .contact-header-title { width: auto; }
    .contact-header-company { padding-top: 50px; font-size: 10px; line-height: 20px; letter-spacing: 1px; }
    .contact-header-address { margin-bottom: 20px; }
    .contact-header-hours { margin-bottom: 20px; }
}