.container-home-contracting { position: relative; z-index: 10; }
.page-home-contracting { padding: 200px 0; display: grid; grid-template-columns: 784px 32px 512px; grid-template-rows: 1fr; grid-column-gap: 0px; grid-row-gap: 0px; }
.home-contracting-image { grid-area: 1 / 1 / 2 / 2; margin-top: 70px; }
.home-contracting-content { grid-area: 1 / 3 / 2 / 4; }
.home-contracting-title { @extend .elements-text-title; padding: 0 0 30px; }
.home-contracting-text { @extend .elements-text-excerpt; }
.home-contracting-link { display: none; }
@media only screen and (max-width: $size-wide) {
    .page-home-contracting { padding: 180px 0; grid-template-columns: 736px 32px 512px; }
}
@media only screen and (max-width: $size-desktop) {
    .page-home-contracting { padding: 160px 0; grid-template-columns: 656px 32px 452px; }
}
@media only screen and (max-width: $size-laptop) {
    .page-home-contracting { padding: 140px 0; grid-template-columns: 512px 24px 360px; }
    .home-contracting-image { margin-top: 50px; }
    .home-contracting-title { padding-bottom: 20px; }
}
@media only screen and (max-width: $size-tablet) {
    // .container-home-contracting { z-index: 1; }
    .page-home-contracting { padding: 120px 0; display: block; }
    .home-contracting-image { position: relative; left: -46px; right: -46px; width: calc(100% + 92px); }
    .home-contracting-content { width: 414px; padding: 0 0 75px 88px; }
    .home-contracting-link {
        & { display: inline-block; padding-top: 50px; font-size: 12px; line-height: 20px; text-transform: uppercase; letter-spacing: 3px; font-weight: 700; color: $color-gray-3d; position: relative; }
        &:before { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; background-color: $color-gray-3d; width: 0; transition: width 0.4s ease; z-index: 1; }
        &:hover:before { width: 100%; }
    }
}
@media only screen and (max-width: $size-phone) {
    .page-home-contracting { padding: 60px 0; display: block; }
    .home-contracting-image { position: relative; left: -25px; right: -25px; width: calc(100% + 50px); margin-top: 0; }
    .home-contracting-content { width: auto; padding: 0 0 30px 48px; }
    .home-contracting-link { padding-top: 30px; font-size: 9px; line-height: 16px; letter-spacing: 2px; }
}