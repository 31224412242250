// ---- RESET ----
* { margin: 0; padding: 0; }
*, *:before, *:after { border: 0; outline: none; }
ol, ul { list-style: none; }
a, a:focus, a:link, a:visited, a:hover { outline: none; text-decoration: none; -webkit-tap-highlight-color: transparent; }
a:focus { color: inherit; }
body, input, textarea, button, select { -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: 100%; outline: none; }
h1, h2, h3, h4, h5, h6 { font-weight: normal; }
fieldset { border: 0; }
input, textarea, select, button { color: inherit; font: inherit; letter-spacing: inherit; }
button, input[type="submit"], label[for] { cursor: pointer; }
button::-moz-focus-inner, input::-moz-focus-inner { padding: 0; border: 0; }
optgroup { font-weight: 700; }
table { border-collapse: collapse; border-spacing: 0; }
button * { pointer-events: none; }
strong, b { font-weight: bold; }
.pointer-events-disable * { pointer-events: none; }