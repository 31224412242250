.home-triangle { position: absolute; top: 0; left: 0; z-index: 2; height: 800px; width: 512px; }
.home-triangle-end { transition: transform 0.2s ease; }

.home-triangle-inside {
    & { position: absolute; top: 0; left: 0; width: 0; height: 0; border-style: solid; border-width: 0 0 800px 512px; border-color: transparent transparent $color-gold transparent; transition: all 0.4s ease; }
    .home-triangle-0 & { transform: translate(-256px, 90px); }
    .home-triangle-1 & { transform: translate(0, 0) rotate(90deg); border-bottom-color: $color-triangle-2; }
    .home-triangle-2 & { transform: translate(-809px, 120px) rotate(180deg); border-bottom-color: $color-triangle-3; }
    .home-triangle-3 &,
    .home-triangle-4 & { transform: translate(0, 0) rotate(270deg); border-bottom-color: $color-triangle-4; }
}
@media only screen and (max-width: $size-wide) {
    .home-triangle-inside {
        .home-triangle-2 & { transform: translate(-730px, 120px) rotate(180deg); }
        .home-triangle-3 &,
        .home-triangle-4 & { transform: translate(-24px, -20px) rotate(270deg); }
    }
}
@media only screen and (max-width: $size-desktop) {
    .home-triangle-inside {
        .home-triangle-2 & { transform: translate(-680px, 120px) rotate(180deg); }
        .home-triangle-3 &,
        .home-triangle-4 & { transform: translate(-94px, -40px) rotate(270deg); }
    }
}
@media only screen and (max-width: $size-laptop) {
    .home-triangle { height: 566px; width: 360px; }
    .home-triangle-inside {
        & { border-width: 0 0 566px 360px; }
        .home-triangle-0 & { transform: translate(-72px, 116px); }
        .home-triangle-2 & { transform: translate(-480px, 120px) rotate(180deg); }
        .home-triangle-3 &,
        .home-triangle-4 & { transform: translate(-13px, -20px) rotate(270deg); }
    }
}
@media only screen and (max-width: $size-tablet) {
    .home-triangle { height: 600px; width: 384px; }
    .home-triangle-inside {
        & { border-width: 0 0 600px 384px; }
        .home-triangle-0 & { transform: translate(-184px, 290px); }
        .home-triangle-1 & { transform: translate(-230px, 0) rotate(90deg); }
        .home-triangle-2 & { transform: translate(-338px, 120px) rotate(180deg); }
        .home-triangle-3 &,
        .home-triangle-4 & { transform: translate(-154px, 42px) rotate(270deg); }
    }
}
@media only screen and (max-width: $size-phone) {
    .home-triangle { height: 200px; width: 128px; }
    .home-triangle-inside {
        & { border-width: 0 0 200px 128px; }
        .home-triangle-0 & { transform: translate(0, 240px); }
        .home-triangle-1 & { transform: translate(-90px, 0) rotate(90deg); }
        .home-triangle-2 & { transform: translate(-111px, 80px) rotate(180deg); }
        .home-triangle-3 &,
        .home-triangle-4 & { transform: translate(-29px, 90px) rotate(270deg); }
    }
}