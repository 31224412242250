$color-white: #fff;
$color-black: #000;

$color-gray-32: #322F31; // foote bg
$color-gray-3d: #3D3D3D;
$color-gray-53: #535353;
$color-gray-d8: #d8d8d8;
$color-gray-f0: #f0f0f0;
$color-gray-f7: #F7F7F7;

$color-gray-line: #E8E8E8;

$color-gold: #DBB864;
$color-red: #FF8080;

$color-triangle-2: #CFB49F;
$color-triangle-3: #B1CAD3;
$color-triangle-4: #CCA186;
