.container-single-intro { }
.page-single-intro { padding: 300px 0 220px; display: flex; flex-flow: row nowrap; justify-content: space-between; }
.single-intro-header { width: 440px; }
.single-intro-title {
    & { @extend .elements-text-title; }
    span { color: $color-gold; }
}
.single-intro-details { padding-top: 8px; width: 784px; display: flex; flex-flow: row wrap; }
.single-intro-item {
    & { width: 392px; padding-bottom: 20px; }
    &:last-child { padding-bottom: 0; }
}
.single-intro-item-label { @extend .elements-text-excerpt; }
.single-intro-item-value { @extend .elements-text-excerpt; color: $color-gold; }
@media only screen and (max-width: $size-wide) {
    .page-single-intro { padding: 240px 0 150px; }
    .single-intro-details { width: 700px; }
    .single-intro-item { width: 340px; }
}
@media only screen and (max-width: $size-laptop) {
    .single-intro-header { width: 284px; }
    .page-single-intro { padding: 190px 0 100px; }
    .single-intro-details { padding-top: 4px; width: 560px; }
    .single-intro-item {
        & { width: 270px; padding-bottom: 15px; }
        &:last-child { padding-bottom: 0; }
    }
}
@media only screen and (max-width: $size-tablet) {
    .page-single-intro { padding: 170px 0 80px; width: 560px; margin: 0 auto; display: block; }
    .single-intro-header { width: auto; padding-bottom: 80px; }
    .single-intro-details { padding-top: 0; width: auto; }
    .single-intro-item { width: 270px; }
}
@media only screen and (max-width: $size-phone) {
    .page-single-intro { padding: 110px 0 60px; width: auto; margin: 0; }
    .single-intro-header { padding-bottom: 60px; }
    .single-intro-details { }
    .single-intro-item {
        & { width: 50%; padding-bottom: 10px; }
        &:last-child { padding-bottom: 0; }
    }
}
@media only screen and (max-width: 374px) {
    .single-intro-details { display: block; }
    .single-intro-item { width: auto; }
}