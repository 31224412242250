.layout { width: 1600px; margin: 0 auto; }
.layout-padding-hor { box-sizing: border-box; padding-left: 136px; padding-right: 136px; }
// .layout-bg { @extend .element-lines-bg; }
@media only screen and (max-width: $size-wide) {
    .layout { width: 1440px; }
    .layout-padding-hor { padding-left: 80px; padding-right: 80px; }
}
@media only screen and (max-width: $size-desktop) {
    .layout { width: 1280px; }
    .layout-padding-hor { padding-left: 70px; padding-right: 70px; }
}
@media only screen and (max-width: $size-laptop) {
    .layout { width: 1024px; }
    .layout-padding-hor { padding-left: 62px; padding-right: 62px; }
}
@media only screen and (max-width: $size-tablet) {
    .layout { width: 768px; }
    .layout-padding-hor { padding-left: 46px; padding-right: 46px; }
}
@media only screen and (max-width: $size-phone) {
    .layout { width: 100%; }
    .layout-padding-hor { padding-left: 25px; padding-right: 25px; }
}
@media only screen and (max-width: 320px) {
    .layout { width: 320px; }
}