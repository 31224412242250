.container-home-woodworking { position: relative; z-index: 10; }
.page-home-woodworking { padding: 200px 0; display: grid; grid-template-columns: 512px 168px 512px 136px; grid-template-rows: 1fr; grid-column-gap: 0px; grid-row-gap: 0px; }
.home-woodworking-image { grid-area: 1 / 3 / 2 / 4; }
.home-woodworking-content { grid-area: 1 / 1 / 2 / 2; }
.home-woodworking-title { @extend .elements-text-title; padding: 20px 0 30px; }
.home-woodworking-text { @extend .elements-text-excerpt; }
.home-woodworking-link { display: none; }
@media only screen and (max-width: $size-wide) {
    .page-home-woodworking { padding: 180px 0; grid-template-columns: 512px 148px 512px 108px; }
}
@media only screen and (max-width: $size-desktop) {
    .page-home-woodworking { padding: 160px 0; grid-template-columns: 480px 116px 480px 64px; }
}
@media only screen and (max-width: $size-laptop) {
    .page-home-woodworking { padding: 140px 0; grid-template-columns: 360px 116px 360px 64px; }
    .home-woodworking-title { padding: 20px 0; }
}
@media only screen and (max-width: $size-tablet) {
    .page-home-woodworking { padding: 120px 0; grid-template-columns: 240px 48px 328px 60px; }
    .home-woodworking-image { padding-top: 72px; }
    .home-woodworking-title { padding: 0 0 50px; }
    .home-woodworking-link {
        & { display: inline-block; padding-top: 50px; font-size: 12px; line-height: 20px; text-transform: uppercase; letter-spacing: 3px; font-weight: 700; color: $color-gray-3d; position: relative; }
        &:before { content: ''; position: absolute; bottom: 1px; left: 0; height: 1px; background-color: $color-gray-3d; width: 0; transition: width 0.4s ease; z-index: 1; }
        &:hover:before { width: 100%; }
    }
}
@media only screen and (max-width: $size-phone) {
    .page-home-woodworking { padding: 60px 0 0; display: block; }
    .home-woodworking-content { padding-bottom: 30px; }
    .home-woodworking-image { position: relative; left: -25px; right: -25px; width: calc(100% + 50px); padding-top: 0; }
    .home-woodworking-title { padding: 0 0 30px; }
    .home-woodworking-link { padding-top: 30px; font-size: 9px; line-height: 16px; letter-spacing: 2px; }
}